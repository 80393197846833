import logo from './logo.svg';
import './App.css';
import { MainRoutes } from "./Routes/MainRoutes";
import { Navbar } from "./Components/Navbar";
import { Footer } from "./Components/Footer";

function App() {
  return (
    <div className="App">
      <Navbar/>
      <MainRoutes />
      <Footer/>
    </div>
  );
}

export default App;

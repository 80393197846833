import "./Customisation.css";
import { useState , useRef} from "react";
import { useNavigate } from "react-router-dom";

export const Customisation = () => {
  const [data,setData]=useState({
   size:"",
   dimension:"",
   thickness:"",
   top_layer:"",
   bottom_layer:"",
  });
  const [king, setKing] = useState(true);
  const [queen, setQueen] = useState(false);
  const [double, setDouble] = useState(false);
  const [single, setSingle] = useState(false);
  const [fourinch, setFourinch] = useState(true);
  const [fiveinch, setFiveinch] = useState(false);
  const [sixinch, setSixinch] = useState(false);
  const [eightinch, setEightinch] = useState(false);

  const navigate = useNavigate();

 const sizeRef = useRef(null);
  const dimensionRef = useRef(null);
const thicknessRef = useRef(null);
const topLayerRef = useRef(null);
const bottomLayerRef = useRef(null);

  const CustomiseDetails=()=>{
    if (
      data.size !== "" &&
      data.dimension !== "" &&
      data.thickness !== "" &&
      data.top_layer !== "" &&
      data.bottom_layer !== ""
    ) {
      navigate("/customisemattress", {
        state: data,
      });
    } if (data.size === "") {
      if (sizeRef.current) {
        sizeRef.current.style.display = "block";
      }
    } if (data.dimension === "") {
      if (dimensionRef.current) {
        dimensionRef.current.style.display = "block";
      }
    }  if (data.thickness === "") {
      if (thicknessRef.current) {
        thicknessRef.current.style.display = "block";
      }
    }  if (data.top_layer === "") {
      if (topLayerRef.current) {
        topLayerRef.current.style.display = "block";
      }
    } if (data.bottom_layer === "") {
      if (bottomLayerRef.current) {
        bottomLayerRef.current.style.display = "block";
      }
    }
  }

  const Kingsize_data = ["72*72", "75*72", "78*72", "84*72"];

  const Queensize_data = ["72*60", "75*60", "78*60", "72*66", "75*66", "78*66"];

  const Doublesize_data = ["72*48", "75*48"];

  const Singlesize_data = ["72*36", "75*36", "78*36"];


console.log(data)
  const handleKingSize = (e) => {
    setQueen(false);
    setDouble(false);
    setSingle(false);
    setKing(true);

    const updatedData = {
      ...data,
      size: e.target.textContent,
    };
    setData(updatedData);
  };

  const handleQueenSize = (e) => {
    setKing(false);
    setDouble(false);
    setSingle(false);
    setQueen(true);

     const updatedData = {
       ...data,
       size: e.target.textContent,
     };
     setData(updatedData);


  };

  const handleDoubleSize = (e) => {
    setKing(false);
    setQueen(false);
    setSingle(false);
    setDouble(true);

     const updatedData = {
      ...data,
      size: e.target.textContent,
    };
    setData(updatedData);

  };

  const handleSingleSize = (e) => {
    setKing(false);
    setQueen(false);
    setDouble(false);
    setSingle(true);

     const updatedData = {
      ...data,
      size: e.target.textContent,
    };
    setData(updatedData);

  };

  const handleDimension=(e)=>{
 const updatedData = {
      ...data,
      dimension: e.target.textContent,
    };
    setData(updatedData);
  }


    const handleTopLayer = (e) => {
      const updatedData = {
        ...data,
        top_layer: e.target.textContent,
      };
      setData(updatedData);
    };

const handleBottomLayer = (e) => {
  const updatedData = {
    ...data,
    bottom_layer: e.target.textContent,
  };
  setData(updatedData);
};

  const Thickness_data = {
    "four_inch": {
      "Top_layer": [
        "High Resilience Foam - 1 Inch",
        "Super Soft Foam - 1 Inch",
        "Memory Foam - 1 Inch",
      ],
      "Bottom_layer": ["Rebonded - 3 Inch"],
    },
    "five_inch": {
      "Top_layer": [
        "High Resilience Foam - 1 Inch",
        "Super Soft Foam - 1 Inch",
        "Memory Foam - 1 Inch",
        "High Resilience Foam - 2 Inch",
        "Super Soft Foam - 2 Inch",
        "Latex - 2 Inch",
        "Memory + Supersoft -2 Inch",
      ],
      "Bottom_layer": [
        "Rebonded - 3 Inch",
        "Rebonded - 4 Inch",
        "High Resilence Foam - 4 Inch",
      ],
    },
    "six_inch": {
      "Top_layer": [
        "High Resilience Foam - 1 Inch",
        "Super Soft Foam - 1 Inch",
        "Memory Foam - 1 Inch",
        "High Resilience Foam - 2 Inch",
        "Super Soft Foam - 2 Inch",
        "Latex - 2 Inch",
        "Memory + Supersoft -2 Inch",
      ],
      "Bottom_layer": [
        "Rebonded - 4 Inch",
        "Rebonded - 5 Inch",
        "High Resilence Foam - 4 Inch",
        "High Resilence Foam - 5 Inch",
      ],
    },
    "eight_inch": {
      "Top_layer": [
        "High Resilience Foam - 2 Inch",
        "Super Soft Foam - 2 Inch",
        "Latex - 2 Inch",
        "Memory + Supersoft -2 Inch",
      ],
      "Bottom_layer": ["Bonnel Spring - 6 Inch", "Pocket Spring - 6 Inch"],
    },
  };


  const handle4Inch=(e)=>{
    setFiveinch(false);
    setEightinch(false);
    setSixinch(false);
     setFourinch(true);

     const updatedData = {
       ...data,
       thickness: e.target.textContent,
     };
     setData(updatedData);
  }

  const handle5Inch=(e)=>{
    setEightinch(false);
    setFourinch(false);
    setSixinch(false);
     setFiveinch(true);

     const updatedData = {
       ...data,
       thickness: e.target.textContent,
     };
     setData(updatedData);
  }


  const handle6Inch = (e) => {
     setFiveinch(false);
     setEightinch(false);
     setFourinch(false);
    setSixinch(true);

    const updatedData = {
      ...data,
      thickness: e.target.textContent,
    };
    setData(updatedData);
  };

  const handle8Inch = (e) => {
     setFiveinch(false);
     setFourinch(false);
     setSixinch(false);
    setEightinch(true);

    const updatedData = {
      ...data,
      thickness: e.target.textContent,
    };
    setData(updatedData);
  };
  return (
    <div id="customise-main-page-main-wrapper">
      <div id="customise-main-page-heading">Customise Your Mattress</div>
      <div id="customise-main-page-sub-heading">
        Share your specific needs, and our dedicated team will reach out to you
        promptly!
      </div>

      <div id="customise-main-page-custom-selection-wrap">
        <div id="customise-main-page-custom-selection-size-wrap">
          <div id="customise-main-page-custom-selection-size-title">
            Choose Mattress Size
          </div>
          <div id="customise-main-page-custom-selection-size-content-wrap">
            <div className="customise-main-page-custom-selection-size-content">
              <div onClick={handleKingSize}>King Size</div>
              <div>
                <img src="./images/customise-img/vector.png" alt="" />
              </div>
            </div>

            <div className="customise-main-page-custom-selection-size-content">
              <div onClick={handleQueenSize}>Queen Size</div>
              <div>
                <img src="./images/customise-img/vector.png" alt="" />
              </div>
            </div>

            <div className="customise-main-page-custom-selection-size-content">
              <div onClick={handleDoubleSize}>Double Size</div>
              <div>
                <img src="./images/customise-img/vector.png" alt="" />
              </div>
            </div>

            <div className="customise-main-page-custom-selection-size-content">
              <div onClick={handleSingleSize}>Single Size</div>
              <div>
                <img src="./images/customise-img/vector.png" alt="" />
              </div>
            </div>
          </div>
          <div className="customise-main-page-error-message" ref={sizeRef}>
            Please select any one of the category
          </div>
        </div>

        <div id="customise-main-page-custom-selection-dimension-wrap">
          <div id="customise-main-page-custom-selection-dimension-title">
            Mattress Dimensions
          </div>
          {king && (
            <div id="customise-main-page-custom-selection-dimension-content-wrap">
              {Kingsize_data.map((item) => (
                <div
                  className="customise-main-page-custom-selection-dimension-content"
                  onClick={handleDimension}
                >
                  <div>{item}</div>
                  <div>
                    <img src="./images/customise-img/Mattress.png" alt="" />
                  </div>
                </div>
              ))}
            </div>
          )}

          {queen && (
            <div id="customise-main-page-custom-selection-dimension-content-wrap">
              {Queensize_data.map((item) => (
                <div
                  className="customise-main-page-custom-selection-dimension-content"
                  onClick={handleDimension}
                >
                  <div>{item}</div>
                  <div>
                    <img src="./images/customise-img/Mattress.png" alt="" />
                  </div>
                </div>
              ))}
            </div>
          )}

          {double && (
            <div id="customise-main-page-custom-selection-dimension-content-wrap">
              {Doublesize_data.map((item) => (
                <div
                  className="customise-main-page-custom-selection-dimension-content"
                  onClick={handleDimension}
                >
                  <div>{item}</div>
                  <div>
                    <img src="./images/customise-img/Mattress.png" alt="" />
                  </div>
                </div>
              ))}
            </div>
          )}

          {single && (
            <div id="customise-main-page-custom-selection-dimension-content-wrap">
              {Singlesize_data.map((item) => (
                <div
                  className="customise-main-page-custom-selection-dimension-content"
                  onClick={handleDimension}
                >
                  <div>{item}</div>
                  <div>
                    <img src="./images/customise-img/Mattress.png" alt="" />
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="customise-main-page-error-message" ref={dimensionRef}>
            Please select any one of the category
          </div>
        </div>

        <div id="customise-main-page-custom-selection-thickness-wrap">
          <div id="customise-main-page-custom-selection-thickness-title">
            Choose Mattress Thickness
          </div>
          <div id="customise-main-page-custom-selection-thickness-content-wrap">
            <div
              className="customise-main-page-custom-selection-thickness-content"
              onClick={handle4Inch}
            >
              <div>4 Inch</div>
              <div>
                <img src="./images/customise-img/Mattress.png" alt="" />
              </div>
            </div>

            <div
              className="customise-main-page-custom-selection-thickness-content"
              onClick={handle5Inch}
            >
              <div>5 Inch</div>
              <div>
                <img src="./images/customise-img/Mattress.png" alt="" />
              </div>
            </div>

            <div
              className="customise-main-page-custom-selection-thickness-content"
              onClick={handle6Inch}
            >
              <div>6 Inch</div>
              <div>
                <img src="./images/customise-img/Mattress.png" alt="" />
              </div>
            </div>

            <div
              className="customise-main-page-custom-selection-thickness-content"
              onClick={handle8Inch}
            >
              <div>8 Inch</div>
              <div>
                <img src="./images/customise-img/Mattress.png" alt="" />
              </div>
            </div>
          </div>
          <div className="customise-main-page-error-message" ref={thicknessRef}>
            Please select any one of the category
          </div>
        </div>

        <div id="customise-main-page-custom-selection-toplayer-wrap">
          <div id="customise-main-page-custom-selection-toplayer-title">
            Choose Mattress Top Layer
          </div>
          {fourinch && (
            <div id="customise-main-page-custom-selection-toplayer-content-wrap">
              {Thickness_data.four_inch.Top_layer.map((item) => (
                <div
                  className="customise-main-page-custom-selection-toplayer-content"
                  onClick={handleTopLayer}
                >
                  <div>{item}</div>
                  <div>
                    <img src="./images/customise-img/Mattress.png" alt="" />
                  </div>
                </div>
              ))}
            </div>
          )}

          {fiveinch && (
            <div id="customise-main-page-custom-selection-toplayer-content-wrap">
              {Thickness_data.five_inch.Top_layer.map((item) => (
                <div
                  className="customise-main-page-custom-selection-toplayer-content"
                  onClick={handleTopLayer}
                >
                  <div>{item}</div>
                  <div>
                    <img src="./images/customise-img/Mattress.png" alt="" />
                  </div>
                </div>
              ))}
            </div>
          )}

          {sixinch && (
            <div id="customise-main-page-custom-selection-toplayer-content-wrap">
              {Thickness_data.six_inch.Top_layer.map((item) => (
                <div
                  className="customise-main-page-custom-selection-toplayer-content"
                  onClick={handleTopLayer}
                >
                  <div>{item}</div>
                  <div>
                    <img src="./images/customise-img/Mattress.png" alt="" />
                  </div>
                </div>
              ))}
            </div>
          )}

          {eightinch && (
            <div id="customise-main-page-custom-selection-toplayer-content-wrap">
              {Thickness_data.eight_inch.Top_layer.map((item) => (
                <div
                  className="customise-main-page-custom-selection-toplayer-content"
                  onClick={handleTopLayer}
                >
                  <div>{item}</div>
                  <div>
                    <img src="./images/customise-img/Mattress.png" alt="" />
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="customise-main-page-error-message" ref={topLayerRef}>
            Please select any one of the category
          </div>
        </div>

        <div id="customise-main-page-custom-selection-bottomlayer-wrap">
          <div id="customise-main-page-custom-selection-bottomlayer-title">
            Choose Mattress Bottom Layer
          </div>
          {fourinch && (
            <div id="customise-main-page-custom-selection-bottomlayer-content-wrap">
              {Thickness_data.four_inch.Bottom_layer.map((item) => (
                <div
                  className="customise-main-page-custom-selection-bottomlayer-content"
                  onClick={handleBottomLayer}
                >
                  <div>{item}</div>
                  <div>
                    <img src="./images/customise-img/Mattress.png" alt="" />
                  </div>
                </div>
              ))}
            </div>
          )}

          {fiveinch && (
            <div id="customise-main-page-custom-selection-bottomlayer-content-wrap">
              {Thickness_data.five_inch.Bottom_layer.map((item) => (
                <div
                  className="customise-main-page-custom-selection-bottomlayer-content"
                  onClick={handleBottomLayer}
                >
                  <div>{item}</div>
                  <div>
                    <img src="./images/customise-img/Mattress.png" alt="" />
                  </div>
                </div>
              ))}
            </div>
          )}

          {sixinch && (
            <div id="customise-main-page-custom-selection-bottomlayer-content-wrap">
              {Thickness_data.six_inch.Bottom_layer.map((item) => (
                <div
                  className="customise-main-page-custom-selection-bottomlayer-content"
                  onClick={handleBottomLayer}
                >
                  <div>{item}</div>
                  <div>
                    <img src="./images/customise-img/Mattress.png" alt="" />
                  </div>
                </div>
              ))}
            </div>
          )}

          {eightinch && (
            <div id="customise-main-page-custom-selection-bottomlayer-content-wrap">
              {Thickness_data.eight_inch.Bottom_layer.map((item) => (
                <div
                  className="customise-main-page-custom-selection-bottomlayer-content"
                  onClick={handleBottomLayer}
                >
                  <div>{item}</div>
                  <div>
                    <img src="./images/customise-img/Mattress.png" alt="" />
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="customise-main-page-error-message" ref={bottomLayerRef}>
            Please select any one of the category
          </div>
        </div>

        <div id="customise-main-page-custom-selection-buttons-wrap">
          <button id="customise-main-page-custom-selection-cancel-button">
            CANCEL
          </button>
          <button
            id="customise-main-page-custom-selection-next-button"
            onClick={CustomiseDetails}
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  );
};

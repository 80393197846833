import "./CustomMattress.css";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

export const CustomMattress=()=>{
const [data, setData] = useState("");

    const location = useLocation();
  
  useEffect(() => {
      setData(location.state);
  }, [location.state]);

 useEffect(() => {
     window.scrollTo(0, 0);
   }, []);

    return (
      <div id="customise-final-page-main-wrapper">
        <div id="customise-final-page-heading">Customise Your Mattress</div>
        <div id="customise-final-page-content-wrap">
          <div id="customise-final-page-content-image-wrap">
            <img
              src="./images/customise-img/customise-final.png"
              alt=""
              id="customise-final-page-content-image"
            />
          </div>
          <div id="customise-final-page-content-details-wrap">
            <div id="customise-final-page-content-details-title">
              Mattress Details -
            </div>
           <div id="customise-final-page-content-details">
              {data.size} ({data.dimension}) -{" "}
              {data.thickness}, Top Layer - {data.top_layer}
              , Bottom layer - {data.bottom_layer}.
            </div>
            <button id="customise-final-page-content-call-back-button">
              Request a call back
            </button>
          </div>
        </div>
      </div>
    );
}
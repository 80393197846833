import "./BestSeller.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";

export const Bestseller=()=>{

  const navigate = useNavigate();

 const toEcoFoam=()=>{
navigate("/category", {
  state: {
    id: 1,
    title: "Eco Range - Eco Foam Mattress",
    tagline: "Sleep like a dream on our blissfully comfortable mattress!",
    description:
      "Eco Foam is an economical range foam mattress offering from the house of Dreamwell. Eco foam comes in with premium quality PU foam which is enhanced with Quilt on one side using the best-in-class visco satin fabric. This mattress is ideal for people looking for a soft mattress. We rate this mattress as medium soft and is advisable for the age group below 35 years.",
    feel: "Soft Mattress, Medium Soft",
    material: "Premium quality PU Foam",
    thickness: "14’’",
    warranty: "1 Year",
    mat_img:"./images/Mattress-img/eco.png"
  },
});
  }

  const toOrtho_O_Bond = () => {
    navigate("/category", {
      state: {
        id: 2,
        title: "Orthopedic - Ortho-O-Bond Mattress",
        tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "Ortho-o-bond is undoubtedly our best selling mattress. This mattress is engineered to provide you the right mix of firmness and comfort. Ortho-o-bond comes with super soft foam on the top which provides the right resilience force along with finest grade of firm rebounded foam. This mattress comes with our special triple anti microbial fabric to keep you protected and keep your bed space safe and hygienic.",
        feel: "Medium Firm",
        material: "Super soft Foam",
        thickness: "5'6’’",
        warranty: "7 Years",
        mat_img: "./images/Mattress-img/ortho-o-bond.png",
      },
    });
  };


  const toNatural_Latex = () => {
    navigate("/category", {
      state: {
        id: 2,
        title: "Luxury Range - Natural Latex Plus Mattress",
        tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "Bring the best of nature into your bedroom with our Natural Latex mattress. Latex is concocted from the sap of rubber tree and is completely natural. Latex is naturally resilient and supportive which makes it a perfect choice to sleep on. Latex is naturally cool and helps in marinating optimum temperature even during those stubborn summer nights. The high GSM fabric is cotton induced which makes it more soft to provide you more comfort. We would highly recommend this mattress from the house of Dreamwell. We rate this mattress as medium soft and highly resilient on our comfort meter.",
        feel: "Medium Soft",
        material: "Latex Foam and rebonded foam",
        thickness: "6’’",
        warranty: "10 Years",
        mat_img: "./images/Mattress-img/natural-latex.png",
      },
    });
  };


  const toOrtho_O_Cocoon = () => {
    navigate("/category", {
      state: {
        id: 5,
        title: "Orthopedic - Ortho-O-Cocoon Mattress",
        tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "Do you know the feeling of waking up energized and a zeal to seize the day? Neither did we until we made this premium offering from Dreamwell. Ortho-o-cocoon is uniquely designed with twin euro top with one side being firm and other being soft. This is a two in one mattress specially designed to the choosy you. You can flip the mattress and use soft or firm side based on your preference. This mattress comes with super soft foam on one side and firm High resilience foam on the other side. The fabric is High GSM knitted to add that much more classiness to your sweet room.",
        feel: "Medium Firm",
        material: "Super soft Foam, high resiliance foam and rebonded foam",
        thickness: "6’’",
        warranty: "7 Years",
        mat_img: "./images/Mattress-img/ortho-o-cocoon.png",
      },
    });
  };

const toOrtho_O_Bond_ET = () => {
  navigate("/category", {
    state: {
      id: 3,
      title: "Orthopedic - Ortho-O-Bond (ET) Mattress",
      tagline: "Sleep like a dream on our blissfully comfortable mattress!",
      description:
        "Ortho o bond(E.T) is the end of your quest to a genuine orthopedic mattress. This is a result of years of experience and collaboration with expert from the orthopedic field to deliver a truly world class orthopedic mattress. The unique euro top over the firm rebonded block is configured to provide you with unique sleeping experience. This mat- tress cradles the body to provide to offer the right posture even in deep sleep. We rate this mattress as medium firm in our comfort meter.",
      feel: "Medium Firm",
      material: "Rebonded Foam and super soft foam",
      thickness: "5'6’’",
      warranty: "10 Years",
      mat_img: "./images/Mattress-img/ortho-o-bond(ET).png",
    },
  });
};

const toSuper_Coco_Gel = () => {
  navigate("/category", {
    state: {
      id: 3,
      title: "Eco Range - Super Coco Gel Mattress",
      tagline: "Sleep like a dream on our blissfully comfortable mattress!",
      description:
        "Super coco gel mattress is an enhanced version of cool gel mattress. This mattress is recommended for those seeking ultimate comfort and those who want to pamper themself to an extremely comfortable yet soft mattress. The super soft layer on the top gives a feather soft feel to this mattress. Super coco gel mattress comes with best-in-class jacquard fabric quilted on both side with super soft PU foam. We rate this mattress as soft on our comfort meter",
      feel: "Soft",
      material: "Super soft Foam with cool gel foam",
      thickness: "5’’",
      warranty: "7 Years",
      mat_img: "./images/Mattress-img/super-coco.png",
    },
  });
};


 const toMemo_Flex = () => {
   navigate("/pillowcategory", {
     state: {
       id: 3,
       title: "Memo Flex",
       tagline:
         "The Memo Flex Pillow is a perfect blend of comfort and support for a restful night’s sleep.",
       description:
         "It keeps the hand shape for a bit, and so is said to have a memory of your hand’s impression. That is why this particular type of foam became known as memory foam.Memory foam pillows have emerged to be highly recommended pillows for neck pain relief and sound sleep. There are numerous reasons for memory foam pillow being the best choice for any type of sleepers.",
       feel: "Soft",
       material: "Memory Foam",
     },
   });
 };

 const toCamaellia = () => {
   navigate("/pillowcategory", {
     state: {
       id: 2,
       title: "Camaellia Pillow ",
       tagline:
         "The Camaellia Pillow is a perfect blend of comfort and support for a restful night’s sleep.",
       description:
         "The pillow is made of soft foam with a medium firm feel for sturdy support.The center of the pillow cushions the head while sleeping on your back. In turn, the sides are optimized for side sleeping, with breathable ear well to prevent pressure points from forming where the ears hit the pillow.",
       feel: "Medium Soft",
       material: "Soft Foam",
     },
   });
 };
      var pillow_banner_slider_settings = {
    className: "pillow-banner-slider-component",
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

    return (
      <div id="bestseller-page-main-wrapper">
        <div id="bestseller-page-banner-slider-wrap">
          <Slider {...pillow_banner_slider_settings}>
            <div className="bestseller-page-banner-slider01">
              <div className="bestseller-page-banner-slider-title">
                Dreamwell
              </div>
              <div className="bestseller-page-banner-slider-quote">
                "Tailored Comfort for Your Perfect Night's Rest: Your Dream
                Mattress, Customised Just for You!"
              </div>
              <a href="/contact">
                <button className="bestseller-page-banner-slider-request-btn">
                  Request now
                </button>
              </a>
            </div>

            <div className="bestseller-page-banner-slider02">
              <div className="bestseller-page-banner-slider-title">
                Dreamwell
              </div>
              <div className="bestseller-page-banner-slider-quote">
                "Tailored Comfort for Your Perfect Night's Rest: Your Dream
                Mattress, Customised Just for You!"
              </div>
              <a href="/contact">
                <button className="bestseller-page-banner-slider-request-btn">
                  Request now
                </button>
              </a>
            </div>

            <div className="bestseller-page-banner-slider03">
              <div className="bestseller-page-banner-slider-title">
                Dreamwell
              </div>
              <div className="bestseller-page-banner-slider-quote">
                "Tailored Comfort for Your Perfect Night's Rest: Your Dream
                Mattress, Customised Just for You!"
              </div>
              <a href="/contact">
                <button className="bestseller-page-banner-slider-request-btn">
                  Request now
                </button>
              </a>
            </div>
          </Slider>
        </div>

        <div id="bestseller-page-content-wrap">
          <div id="bestseller-page-content-headline">Our Best Sellers</div>
          <div id="bestseller-page-content-grid-wrap">
            <div
              className="bestseller-page-content-grid"
              onClick={() => {
                toEcoFoam();
              }}
            >
              <div className="bestseller-page-content-grid-img-wrap">
                <img
                  src="./images/Bestseller-img/bestseller01.jpeg"
                  alt=""
                  className="bestseller-page-content-grid-img"
                />
              </div>
              <div className="bestseller-page-content-grid-title">
                Eco Foam Mattress
              </div>
              <div className="bestseller-page-content-grid-rating">
                <Stack spacing={1}>
                  <Rating name="read-only" value="5" readOnly size="small" />
                </Stack>
              </div>
              <div className="bestseller-page-content-grid-description">
                Undoubtedly our best selling mattress.
              </div>
            </div>

            <div
              className="bestseller-page-content-grid"
              onClick={() => {
                toOrtho_O_Bond();
              }}
            >
              <div className="bestseller-page-content-grid-img-wrap">
                <img
                  src="./images/Bestseller-img/bestseller02.jpeg"
                  alt=""
                  className="bestseller-page-content-grid-img"
                />
              </div>
              <div className="bestseller-page-content-grid-title">
                Ortho-o-Bond Mattress
              </div>
              <div className="bestseller-page-content-grid-rating">
                <Stack spacing={1}>
                  <Rating name="read-only" value="5" readOnly size="small" />
                </Stack>
              </div>
              <div className="bestseller-page-content-grid-description">
                Undoubtedly our best selling mattress.
              </div>
            </div>

            <div
              className="bestseller-page-content-grid"
              onClick={() => {
                toNatural_Latex();
              }}
            >
              <div className="bestseller-page-content-grid-img-wrap">
                <img
                  src="./images/Bestseller-img/bestseller03.jpeg"
                  alt=""
                  className="bestseller-page-content-grid-img"
                />
              </div>
              <div className="bestseller-page-content-grid-title">
                Natural Latex Plus Mattress
              </div>
              <div className="bestseller-page-content-grid-rating">
                <Stack spacing={1}>
                  <Rating name="read-only" value="5" readOnly size="small" />
                </Stack>
              </div>
              <div className="bestseller-page-content-grid-description">
                Undoubtedly our best selling mattress.
              </div>
            </div>

            <div
              className="bestseller-page-content-grid"
              onClick={() => {
                toOrtho_O_Cocoon();
              }}
            >
              <div className="bestseller-page-content-grid-img-wrap">
                <img
                  src="./images/Bestseller-img/bestseller04.jpeg"
                  alt=""
                  className="bestseller-page-content-grid-img"
                />
              </div>
              <div className="bestseller-page-content-grid-title">
                Ortho-o-cocoon
              </div>
              <div className="bestseller-page-content-grid-rating">
                <Stack spacing={1}>
                  <Rating name="read-only" value="5" readOnly size="small" />
                </Stack>
              </div>
              <div className="bestseller-page-content-grid-description">
                Undoubtedly our best selling mattress.
              </div>
            </div>

            <div
              className="bestseller-page-content-grid"
              onClick={() => {
                toOrtho_O_Bond_ET();
              }}
            >
              <div className="bestseller-page-content-grid-img-wrap">
                <img
                  src="./images/Bestseller-img/bestseller05.jpeg"
                  alt=""
                  className="bestseller-page-content-grid-img"
                />
              </div>
              <div className="bestseller-page-content-grid-title">
                Ortho-o-Bond (ET)
              </div>
              <div className="bestseller-page-content-grid-rating">
                <Stack spacing={1}>
                  <Rating name="read-only" value="5" readOnly size="small" />
                </Stack>
              </div>
              <div className="bestseller-page-content-grid-description">
                Undoubtedly our best selling mattress.
              </div>
            </div>

            <div
              className="bestseller-page-content-grid"
              onClick={() => {
                toSuper_Coco_Gel();
              }}
            >
              <div className="bestseller-page-content-grid-img-wrap">
                <img
                  src="./images/Bestseller-img/bestseller06.jpeg"
                  alt=""
                  className="bestseller-page-content-grid-img"
                />
              </div>
              <div className="bestseller-page-content-grid-title">
                Super Coco Gel
              </div>
              <div className="bestseller-page-content-grid-rating">
                <Stack spacing={1}>
                  <Rating name="read-only" value="5" readOnly size="small" />
                </Stack>
              </div>
              <div className="bestseller-page-content-grid-description">
                Undoubtedly our best selling mattress.
              </div>
            </div>

            <div
              className="bestseller-page-content-grid"
              onClick={() => {
                toMemo_Flex();
              }}
            >
              <div className="bestseller-page-content-grid-img-wrap">
                <img
                  src="./images/Bestseller-img/bestseller07.webp"
                  alt=""
                  className="bestseller-page-content-grid-img"
                />
              </div>
              <div className="bestseller-page-content-grid-title">
                Memo Flex Pillow
              </div>
              <div className="bestseller-page-content-grid-rating">
                <Stack spacing={1}>
                  <Rating name="read-only" value="5" readOnly size="small" />
                </Stack>
              </div>
              <div className="bestseller-page-content-grid-description">
                Undoubtedly our best selling mattress.
              </div>
            </div>

            <div
              className="bestseller-page-content-grid"
              onClick={() => {
                toCamaellia();
              }}
            >
              <div className="bestseller-page-content-grid-img-wrap">
                <img
                  src="./images/Bestseller-img/bestseller08.webp"
                  alt=""
                  className="bestseller-page-content-grid-img"
                />
              </div>
              <div className="bestseller-page-content-grid-title">
                Camaelia Pillow
              </div>
              <div className="bestseller-page-content-grid-rating">
                <Stack spacing={1}>
                  <Rating name="read-only" value="5" readOnly size="small" />
                </Stack>
              </div>
              <div className="bestseller-page-content-grid-description">
                Undoubtedly our best selling mattress.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
import "./PillowCategory.css";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

export const PillowCategory=()=>{
        const location = useLocation();
        const [data, setData] = useState("");

        useEffect(() => {
          setData(location.state);
        }, [location.state]);
        
        useEffect(() => {
          window.scrollTo(0, 0);
        }, []);
    return (
      <div id="pillow-category-page-main-wrapper">
        <div id="pillow-category-page-desktop-view-flex01-wrap">
          <div>
            <div id="pillow-category-page-banner-image-wrap">
              <img
                src={data.image}
                alt=""
                id="pillow-category-page-banner-image"
              />
            </div>
          </div>

          <div>
            <div id="pillow-category-page-sec01-wrap">
              <div id="pillow-category-page-product-title">{data.title}</div>

              <div id="pillow-category-page-product-tagline">
                {data.tagline}
              </div>

              <div id="pillow-category-page-product-rating">
                <Stack spacing={1}>
                  <Rating name="read-only" value="5" readOnly size="small" />
                </Stack>
              </div>

              <a href="/contact">
                {" "}
                <button id="pillow-category-page-product-shop-btn">
                  Shop now
                </button>{" "}
              </a>
            </div>

            <div id="pillow-category-page-sec02-descp-wrap">
              <div id="pillow-category-page-sec02-descp-headline">
                Description
              </div>
              <div id="pillow-category-page-sec02-descp-content">
                {data.description}
              </div>
            </div>
          </div>
        </div>

        <div id="pillow-category-page-sec03-product-details-wrap">
          <div id="pillow-category-page-sec03-product-details-headline">
            Product Details
          </div>
          <div id="pillow-category-page-sec03-product-details-content-wrap">
            <div className="pillow-category-page-sec03-product-details-flex-wrap">
              <div className="pillow-category-page-sec03-product-details-flex-logo-wrap">
                <img src="./images/Mattress-img/feather.png" alt="" />
              </div>
              <div className="pillow-category-page-sec03-product-details-flex-details-wrap">
                <div>Pillow Feel :</div>
                <div>{data.feel}</div>
              </div>
            </div>

            <div className="pillow-category-page-sec03-product-details-flex-wrap">
              <div className="pillow-category-page-sec03-product-details-flex-logo-wrap">
                <img src="./images/Mattress-img/feather.png" alt="" />
              </div>
              <div className="pillow-category-page-sec03-product-details-flex-details-wrap">
                <div>Pillow Material :</div>
                <div>{data.material}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
import "./Search.css";
import { GoArrowLeft } from "react-icons/go";
import { GoSearch } from "react-icons/go";

export const Search = () => {
  return (
    <div id="search-popup-main-wrap">
      <div id="search-popup-header-wrap">
        <GoArrowLeft id="search-popup-header-arrow-icon" />
        <div id="search-popup-header-search-wrap">
          <input
            type="search"
            id="search-popup-header-search-input"
            placeholder="Search mattress, Pillows and more."
          />
        </div>
        <div id="search-popup-header-search-icon-wrap">
          <GoSearch id="search-popup-header-search-icon" />
        </div>
      </div>

      <div id="search-popup-trending-searches-wrap">
        <div id="search-popup-trending-searches-heading">Trending Searches</div>
        <div id="search-popup-trending-searches-content-main-wrap">
          <div id="search-popup-trending-searches-content-01-wrap">
            <div>
              <img src="./images/Search-img/Trending.png" alt="" />
            </div>
            <div>Pillows</div>
          </div>

          <div id="search-popup-trending-searches-content-02-wrap">
            <div>
              <img src="./images/Search-img/Trending.png" alt="" />
            </div>
            <div>Mattress</div>
          </div>
        </div>
      </div>
    </div>
  );
};

import "./NavList.css";
import { IoAddSharp } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const NavList = ({listClose}) => {
  const [mattress, setMattress] = useState(false);
  const [eco, setEco] = useState(false);
  const [ortho, setOrtho] = useState(false);
  const [luxury, setLuxury] = useState(false);
  const [spring, setSpring] = useState(false);
  const [pillow, setPillow] = useState(false);

  const navigate = useNavigate();

  const mattressToggle = () => {
    setMattress(true);
  };

  const ecoToggle = () => {
    setEco(true);
  };

  const orthoToggle = () => {
    setOrtho(true);
  };

  const luxuryToggle = () => {
    setLuxury(true);
  };

  const springToggle = () => {
    setSpring(true);
  };

  const pillowToggle = () => {
    setPillow(true);
  };

  const toEcoCategory01 = () => {
    navigate("/category", {
      state: {
        id: 1,
        title: "Eco Range - Eco Foam Mattress",
        image: "./images/Mattress-img/eco-foam-banner.jpeg",
        tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "Eco Foam is an economical range foam mattress offering from the house of Dreamwell. Eco foam comes in with premium quality PU foam which is enhanced with Quilt on one side using the best-in-class visco satin fabric. This mattress is ideal for people looking for a soft mattress. We rate this mattress as medium soft and is advisable for the age group below 35 years.",
        feel: "Soft Mattress, Medium Soft",
        material: "Premium quality PU Foam",
        thickness: "14’’",
        warranty: "1 Year",
        mat_img: "./images/Mattress-img/eco.png",
      },
    });
  };

  const toEcoCategory02 = () => {
    navigate("/category", {
      state: {
        id: 2,
        title: "Eco Range - Cool Gel Mattress",
        image: "./images/Mattress-img/cool-gel-banner.jpeg",tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "Cool Gel is a one of kind mattress which is made from PU foam injected with cool gel crystals. The unique property of cool gel crystal helps to optimize body temperature and ensure zero disturb sleep. This mattress comes in 5 inch thickness with Jacquard fabric quilt for superior comfort. We rate this mattress as Medium Firm on our comfort meter.",
        feel: "Medium Firm",
        material: "Premium quality PU Foam with cool gel crystal",
        thickness: "5’’",
        warranty: "5 Years",
        mat_img: "./images/Mattress-img/cool-gel.png",
      },
    });
  };

  const toEcoCategory03 = () => {
    navigate("/category", {
      state: {
        id: 3,
        title: "Eco Range - Super Coco Gel Mattress",
        image: "./images/Mattress-img/super-coco-banner.jpeg",
        tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "Super coco gel mattress is an enhanced version of cool gel mattress. This mattress is recommended for those seeking ultimate comfort and those who want to pamper themself to an extremely comfortable yet soft mattress. The super soft layer on the top gives a feather soft feel to this mattress. Super coco gel mattress comes with best-in-class jacquard fabric quilted on both side with super soft PU foam. We rate this mattress as soft on our comfort meter",
        feel: "Soft",
        material: "Super soft Foam with cool gel foam",
        thickness: "5’’",
        warranty: "7 Years",
        mat_img: "./images/Mattress-img/super-coco.png",
      },
    });
  };

  const toEcoCategory04 = () => {
    navigate("/category", {
      state: {
        id: 4,
        title: "Eco Range - Slim Mattress",
        image: "./images/Mattress-img/slim-banner.jpeg",
        tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "The slim mattress is a premium quality foam mattress designed for convenient travel and ultimate comfort. With its slim profile, lightweight construction, and durable foam material, it offers exceptional comfort and portability. Crafted with high quality foam material, it provides excellent support and cushioning for a restful sleep experience. The Slim Mattress is hypoallergenic and breathable, ensuring a comfortable and allergy-free rest.",
        feel: " ",
        material: "premium quality foam",
        thickness: " ",
        warranty: " ",
        mat_img: " ",
      },
    });
  };

  const toOrthoCategory01 = () => {
    navigate("/category", {
      state: {
        id: 1,
        title: "Orthopedic - Ortho Mattress",
        image: "./images/Mattress-img/ortho-banner.jpeg",
        tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "Ortho mattress is our best-selling mattress in the orthopedic mattress range. This ortho mattress is designed to encouage spine alignment and posture correction even when you slumber in deep sleep. The innovative construc- tion and thoughtful selection of rebonded foam helps in providing optimal support and comfort. Choose this ortho mattress and bid a goodbye to sore backs. This mattress comes with finest knitted fabric quilted with PU foam for better comfort. We rate this mattress as firm on our comfort scale.",
        feel: "Firm",
        material: "Super soft Foam with cool gel foam",
        thickness: "4'5’’",
        warranty: "5 Years",
        mat_img: "./images/Mattress-img/ortho.png",
      },
    });
  };

  const toOrthoCategory02 = () => {
    navigate("/category", {
      state: {
        id: 2,
        title: "Orthopedic - Ortho-O-Bond Mattress",
        image: "./images/Mattress-img/ortho-bond-banner.jpeg",
        tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "Ortho-o-bond is undoubtedly our best selling mattress. This mattress is engineered to provide you the right mix of firmness and comfort. Ortho-o-bond comes with super soft foam on the top which provides the right resilience force along with finest grade of firm rebounded foam. This mattress comes with our special triple anti microbial fabric to keep you protected and keep your bed space safe and hygienic.",
        feel: "Medium Firm",
        material: "Super soft Foam",
        thickness: "5'6’’",
        warranty: "7 Years",
        mat_img: "./images/Mattress-img/ortho-o-bond.png",
      },
    });
  };

  const toOrthoCategory03 = () => {
    navigate("/category", {
      state: {
        id: 3,
        title: "Orthopedic - Ortho-O-Bond (ET) Mattress",
        image: "./images/Mattress-img/ortho-bond-et-banner.jpeg",
        tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "Ortho o bond(E.T) is the end of your quest to a genuine orthopedic mattress. This is a result of years of experience and collaboration with expert from the orthopedic field to deliver a truly world class orthopedic mattress. The unique euro top over the firm rebonded block is configured to provide you with unique sleeping experience. This mat- tress cradles the body to provide to offer the right posture even in deep sleep. We rate this mattress as medium firm in our comfort meter.",
        feel: "Medium Firm",
        material: "Rebonded Foam and super soft foam",
        thickness: "5'6’’",
        warranty: "10 Years",
        mat_img: "./images/Mattress-img/ortho-o-bond(ET).png",
      },
    });
  };

  const toOrthoCategory04 = () => {
    navigate("/category", {
      state: {
        id: 4,
        title: "Orthopedic - Aarogya Mattress",
        image: "./images/Mattress-img/arogya-banner.jpeg",
        tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "Aarogya mattress can be described as research backed and designed to perfection. Aarogya is all about a rejuvenated sleeping experience coupled with protection from micro particle by using anti-viral fabric.Tested and recommended this mattress is your answer to all sleep needs. On our com- fort meter we would rate this mattress as medium firm.",
        feel: "Medium Firm",
        material: "Super soft Foam, high resiliance foam",
        thickness: "6’’",
        warranty: "7 Years",
        mat_img: "./images/Mattress-img/aarogya.png",
      },
    });
  };

  const toOrthoCategory05 = () => {
    navigate("/category", {
      state: {
        id: 5,
        title: "Orthopedic - Ortho-O-Cocoon Mattress",
        image: "./images/Mattress-img/ortho-cocoon-banner.jpeg",
        tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "Do you know the feeling of waking up energized and a zeal to seize the day? Neither did we until we made this premium offering from Dreamwell. Ortho-o-cocoon is uniquely designed with twin euro top with one side being firm and other being soft. This is a two in one mattress specially designed to the choosy you. You can flip the mattress and use soft or firm side based on your preference. This mattress comes with super soft foam on one side and firm High resilience foam on the other side. The fabric is High GSM knitted to add that much more classiness to your sweet room.",
        feel: "Medium Firm",
        material: "Super soft Foam, high resiliance foam and rebonded foam",
        thickness: "6’’",
        warranty: "7 Years",
        mat_img: "./images/Mattress-img/ortho-o-cocoon.png",
      },
    });
  };

  const toLuxuryCategory01 = () => {
    navigate("/category", {
      state: {
        id: 1,
        title: "Luxury Range - Memory Plus Mattress",
        image: "./images/Mattress-img/memory-plus-banner.jpeg",
        tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "Memory plus is an out and out memory foam mattress designed for plush comfort and luxury. This mattress truly redefines the essence of luxury. The High GSM knitted fabric and finest quality memory foam gives you an unparalleled comfort. Featuring super soft foam and rebounded foam for support, helps you sleep by cradling you to sweet slumber every night. This mattress comes with optimum support for ensuring the spine is aligned even in deep sleep. We rate this mattress as soft on our comfort meter.",
        feel: "Soft",
        material: "Super soft Foam, Memory foam and rebonded foam",
        thickness: "6’’",
        warranty: "7 Years",
        mat_img: "./images/Mattress-img/memory-plus.png",
      },
    });
  };

  const toLuxuryCategory02 = () => {
    navigate("/category", {
      state: {
        id: 2,
        title: "Luxury Range - Natural Latex Plus Mattress",
        image: "./images/Mattress-img/latex-banner.jpeg",
        tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "Bring the best of nature into your bedroom with our Natural Latex mattress. Latex is concocted from the sap of rubber tree and is completely natural. Latex is naturally resilient and supportive which makes it a perfect choice to sleep on. Latex is naturally cool and helps in marinating optimum temperature even during those stubborn summer nights. The high GSM fabric is cotton induced which makes it more soft to provide you more comfort. We would highly recommend this mattress from the house of Dreamwell. We rate this mattress as medium soft and highly resilient on our comfort meter.",
        feel: "Medium Soft",
        material: "Latex Foam and rebonded foam",
        thickness: "6’’",
        warranty: "10 Years",
        mat_img: "./images/Mattress-img/natural-latex.png",
      },
    });
  };

  const toSpringCategory01 = () => {
    navigate("/category", {
      state: {
        id: 1,
        title: "Spring Range - Spring King Mattress",
        image: "./images/Mattress-img/spring-king-banner.jpeg",
        tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "Spring king mattress strikes the right balance between support and bounce due to its resilience bonnel spring cage. Foam layer on top provides that much more comfort and much needed support. On our comfort meter we would rate this mattress as soft with a high degree of resilience.",
        feel: "Medium firm",
        material:
          "High density PU Foam , cotton felt, bonnel spring and anti skid fabric.",
        thickness: "6’’",
        warranty: "7 Years",
        mat_img: "./images/Mattress-img/spring-king.png",
      },
    });
  };

  const toSpringCategory02 = () => {
    navigate("/category", {
      state: {
        id: 2,
        title: "Spring Range - Bonnel Plus (ET) Mattress",
        image: "./images/Mattress-img/bonnel-banner.jpeg",
        tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "Bonnel Plus (ET) the best pick on offering in our spring range of mattress. The High Resilience Foam over the bonnel spring cage acts as the right cushion to support your body. The super soft euro top over the bonnel cage provide the right mix of firmness and softness. The Knitted fabric quilted on both side using PU foam makes it a perfect choice for your sound sleep. On our comfort meter would rate this mattress as soft.",
        feel: "Soft",
        material:
          "Highresilience foam, cotton felt, bonnel spring and anti skid fabric.",
        thickness: "8’’",
        warranty: "7 Years",
        mat_img: "./images/Mattress-img/bonnel-plus.png",
      },
    });
  };

  const toSpringCategory03 = () => {
    navigate("/category", {
      state: {
        id: 3,
        title: "Spring Range - Pocket Spring Mattress",
        image: "./images/Mattress-img/pocket-spring-banner.jpeg",
        tagline: "Sleep like a dream on our blissfully comfortable mattress!",
        description:
          "Pocket spring mattress is our offering to those who liked to be cradled to sleep. The pocket spring which are mesh of individual spring fitted in pocket like pouches are arranged to form of cage. This ensures zero disturbance in your sound sleep. The foam padding with high resilient foam on one side and super soft on the other makes it a perfect choice for your sound sleep. On our comfort meter we rate this mattress as soft.",
        feel: "Soft",
        material: "Highresilience foam, cotton felt anti skid fabric.",
        thickness: "8’’",
        warranty: "5 Years",
        mat_img: "./images/Mattress-img/pocket-spring.png",
      },
    });
  };

  const toAzalea = () => {
    navigate("/pillowcategory", {
      state: {
        id: 1,
        title: "Azalea Pillow",
        image: "./images/Pillow-img/pillow01.jpg",
        tagline:
          "The Azalea Pillow is a perfect blend of comfort and support for a restful night’s sleep.",
        description:
          "Crafted with attention to detail, it features a durable and hypoallergenic polyester in-fill, ensuring an allergen-free environment.The natural, breathable materials provide optimal airflow, keeping you cool throughout the night. With it’s re-fluffable and soft design, the pillow offers exceptional comfort and adjustable support.Made from 100% soft fabric, it feels luxurious against your skin. Suitable for back and side sleepers, it promotes proper alignment reducing discomfort.",
        feel: "Soft Mattress, Medium Soft",
        material: "Premium quality PU Foam",
      },
    });
  };

  const toCamaellia = () => {
    navigate("/pillowcategory", {
      state: {
        id: 2,
        title: "Camaellia Pillow ",
        image: "./images/Pillow-img/pillow02.webp",
        tagline:
          "The Camaellia Pillow is a perfect blend of comfort and support for a restful night’s sleep.",
        description:
          "The pillow is made of soft foam with a medium firm feel for sturdy support.The center of the pillow cushions the head while sleeping on your back. In turn, the sides are optimized for side sleeping, with breathable ear well to prevent pressure points from forming where the ears hit the pillow.",
        feel: "Medium Soft",
        material: "Soft Foam",
      },
    });
  };

  const toMemoFlex = () => {
    navigate("/pillowcategory", {
      state: {
        id: 3,
        title: "Memo Flex",
        image: "./images/Pillow-img/pillow03.webp",
        tagline:
          "The Memo Flex Pillow is a perfect blend of comfort and support for a restful night’s sleep.",
        description:
          "It keeps the hand shape for a bit, and so is said to have a memory of your hand’s impression. That is why this particular type of foam became known as memory foam.Memory foam pillows have emerged to be highly recommended pillows for neck pain relief and sound sleep. There are numerous reasons for memory foam pillow being the best choice for any type of sleepers.",
        feel: "Soft",
        material: "Memory Foam",
      },
    });
  };

  return (
    <div id="navigation-list-main-wrapper">
      <AiOutlineClose id="navigation-list-close-button" onClick={listClose}/>
      <div id="navigation-list-mattress-wrap">
        <div className="navigation-list-title-wrap" onClick={mattressToggle}>
          <div className="navigation-list-title">Mattress</div>
          <IoAddSharp className="navigation-list-add-icon" />
        </div>

        {mattress && (
          <div className="navigation-list-category-wrap">
            <div
              className="navigation-list-category-title-wrap"
              onClick={ecoToggle}
            >
              <div className="navigation-list-category-name">
                Eco Range Mattress
              </div>
              <IoAddSharp className="navigation-list-add-icon" />
            </div>
            {eco && (
              <>
                <div
                  className="navigation-list-sub-category"
                  onClick={() => {
                    toEcoCategory01();
                    listClose();
                  }}
                >
                  Eco Foam
                </div>
                <div
                  className="navigation-list-sub-category"
                  onClick={() => {
                    toEcoCategory02();
                    listClose();
                  }}
                >
                  Cool Gel
                </div>
                <div
                  className="navigation-list-sub-category"
                  onClick={() => {
                    toEcoCategory03();
                    listClose();
                  }}
                >
                  Super Coco Gel
                </div>
                <div
                  className="navigation-list-sub-category"
                  onClick={() => {
                    toEcoCategory04();
                    listClose();
                  }}
                >
                  Slim Mattress
                </div>
              </>
            )}
          </div>
        )}

        {mattress && (
          <div className="navigation-list-category-wrap">
            <div
              className="navigation-list-category-title-wrap"
              onClick={orthoToggle}
            >
              <div className="navigation-list-category-name">
                Orthopaedic Mattress
              </div>
              <IoAddSharp className="navigation-list-add-icon" />
            </div>
            {ortho && (
              <>
                {" "}
                <div
                  className="navigation-list-sub-category"
                  onClick={() => {
                    toOrthoCategory01();
                    listClose();
                  }}
                >
                  Ortho
                </div>
                <div
                  className="navigation-list-sub-category"
                  onClick={() => {
                    toOrthoCategory02();
                    listClose();
                  }}
                >
                  Ortho o Bond
                </div>
                <div
                  className="navigation-list-sub-category"
                  onClick={() => {
                    toOrthoCategory03();
                    listClose();
                  }}
                >
                  Ortho o Bond (ET)
                </div>
                <div
                  className="navigation-list-sub-category"
                  onClick={() => {
                    toOrthoCategory04();
                    listClose();
                  }}
                >
                  Arogya
                </div>
                <div
                  className="navigation-list-sub-category"
                  onClick={() => {
                    toOrthoCategory05();
                    listClose();
                  }}
                >
                  Ortho o coccon
                </div>
              </>
            )}
          </div>
        )}

        {mattress && (
          <div className="navigation-list-category-wrap">
            <div
              className="navigation-list-category-title-wrap"
              onClick={luxuryToggle}
            >
              <div className="navigation-list-category-name">
                Luxury Range Mattress
              </div>
              <IoAddSharp className="navigation-list-add-icon" />
            </div>
            {luxury && (
              <>
                {" "}
                <div
                  className="navigation-list-sub-category"
                  onClick={() => {
                    toLuxuryCategory01();
                    listClose();
                  }}
                >
                  Memory Plus
                </div>
                <div
                  className="navigation-list-sub-category"
                  onClick={() => {
                    toLuxuryCategory02();
                    listClose();
                  }}
                >
                  Natural Latex Plus
                </div>
              </>
            )}
          </div>
        )}

        {mattress && (
          <div className="navigation-list-category-wrap">
            <div
              className="navigation-list-category-title-wrap"
              onClick={springToggle}
            >
              <div className="navigation-list-category-name">
                Spring Range Mattress
              </div>
              <IoAddSharp className="navigation-list-add-icon" />
            </div>
            {spring && (
              <>
                {" "}
                <div
                  className="navigation-list-sub-category"
                  onClick={() => {
                    toSpringCategory01();
                    listClose();
                  }}
                >
                  Spring King
                </div>
                <div
                  className="navigation-list-sub-category"
                  onClick={() => {
                    toSpringCategory02();
                    listClose();
                  }}
                >
                  Bonnel Plus ET
                </div>
                <div
                  className="navigation-list-sub-category"
                  onClick={() => {
                    toSpringCategory03();
                    listClose();
                  }}
                >
                  Pocket Spring
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <div id="navigation-list-pillow-wrap">
        <div className="navigation-list-title-wrap" onClick={pillowToggle}>
          <div className="navigation-list-title">Pillows</div>
          <IoAddSharp className="navigation-list-add-icon" />
        </div>
        {pillow && (
          <div className="navigation-list-category-wrap">
            <div
              className="navigation-list-sub-category"
              onClick={() => {
                toAzalea();
                listClose();
              }}
            >
              Azalea
            </div>
            <div
              className="navigation-list-sub-category"
              onClick={() => {
                toCamaellia();
                listClose();
              }}
            >
              Camaellia
            </div>
            <div
              className="navigation-list-sub-category"
              onClick={() => {
                toMemoFlex();
                listClose();
              }}
            >
              Memo Flex
            </div>
          </div>
        )}
      </div>
      <div id="navigation-list-customise-button-wrap">
        <button className="navigation-list-button">Customise</button>
      </div>
      <div id="navigation-list-contact-button-wrap">
        <a href="/contact">
          <button className="navigation-list-button">Contact us</button>
        </a>
      </div>
    </div>
  );
};
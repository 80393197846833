import { Routes, Route  } from "react-router-dom";
import { Home } from "../Components/Home.jsx";
import { About } from "../Components/About.jsx";
import { Mattress } from "../Components/Mattress.jsx";
import { Pillow } from "../Components/Pillow.jsx";
import { MattressCategory } from "../Components/MattressCategory.jsx";
import { PillowCategory } from "../Components/PillowCategory.jsx";
import { Contact } from "../Components/Contact.jsx";
import { Bestseller } from "../Components/BestSeller.jsx";
import { Search } from "../Components/Search.jsx";
import { Customisation } from "../Components/Customisation.jsx";
import { CustomMattress } from "../Components/CustomMattress.jsx";

export const MainRoutes =()=>{
    return (
      <>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/mattress" element={<Mattress />} />
          <Route path="/pillow" element={<Pillow />} />
          <Route path="/category" element={<MattressCategory />} />
          <Route path="/pillowcategory" element={<PillowCategory />} />
          <Route path="/bestseller" element={<Bestseller />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/search" element={<Search />} />
          <Route path="/customise" element={<Customisation />} />
          <Route path="/customisemattress" element={<CustomMattress />} />
        </Routes>
      </>
    );
}
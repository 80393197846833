import"./About.css"

export const About=()=>{
    return (
      <div id="about-us-main-wrapper">
        <div id="about-us-sec01-wrap">
          <img src="./images/About-img/banner.png" alt="" />
        </div>
        <div id="about-us-sec02-wrap">
          <div>"Your Ultimate Comfort, Personalised:</div>
          <div>Our Promise to Deliver Tailored Bliss."</div>
          <div>
            Welcome to DreamwellAs sleep is so essential for our well being, we
            at Dreamwell are devoted to ensuring one objective. Which is to aid
            in ensuring good and comfortable sleep each night.
          </div>
        </div>

        <div id="about-us-sec03-wrap">
          <div>Our Story</div>
          <div>
            Dreamwell started a decade ago with a simple philosophy that in
            one's life span the activity that one does most is to sleep. When
            you technically spend almost 50% of your entire life sleeping, it
            has to be done right!! In a decade of our existence, we have tried
            and devised scientific ways of suggesting mattresses to various
            customers based on their body mass index and sleeping posture.
          </div>
          <div>
            {" "}
            We craft our mattress with the best of raw material to ensure the
            value for money is realized for every mattress that we sell. We will
            continue to strive to deliver a good value for money product by
            using innovative technologies.
          </div>
        </div>

        <div id="about-us-sec04-wrap">
          <img src="./images/About-img/logo.png" alt="" />
        </div>
      </div>
    );
}
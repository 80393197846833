import "./Footer.css"
import { RiArrowDropDownLine } from 'react-icons/ri';
import { AiFillHeart } from "react-icons/ai";
import { useState } from "react";

export const Footer=()=>{
  const [category, setCategory] = useState(false);

  const categoryToggle=()=>{
    setCategory(true);
  }
    return (
      <div id="footer-main-wrapper">
        <div id="footer-sec01-wrap">
          <div id="footer-sec01-desktop-address-wrap">
            <div className="footer-sec01-desktop-view-titles">Address</div>
            <div className="footer-sec01-desktop-view-content">
              PATEL FOAM AGENCIES <br />
              Shed No.2 D-16 Medchal Industrial Area Lane,Opp. Raane Engine
              Valves Company,Medchal Rd Hyderabad, Telangana 501401
            </div>

            <div
              className="footer-sec01-desktop-view-titles"
              id="footer-sec01-desktop-view-contact-title"
            >
              Contact Details
            </div>
            <div className="footer-sec01-desktop-view-content">
              +91 733 0999311
            </div>

            <div
              className="footer-sec01-desktop-view-titles"
              id="footer-sec01-desktop-view-mail-title"
            >
              Mail Id
            </div>
            <div
              className="footer-sec01-desktop-view-content"
              id="footer-sec01-desktop-view-mail-content"
            >
              info.dreamwel@gmail.com
            </div>
          </div>

          <div id="footer-sec01-list01-wrap">
            <div>Company</div>
            <a href="/about">
              <div>About Us</div>
            </a>
            <a href="/contact">
              <div>Contact Us</div>
            </a>
            <div>Testimonials</div>
            <div>FAQs</div>
          </div>
          <div id="footer-sec01-list02-wrap">
            <div>Help</div>
            <div>Warranty Registrations</div>
            <div>Privacy Policy</div>
            <div>Refund Policy</div>
            <div>Terms & Conditions</div>
            <div>Disclaimer</div>
          </div>
        </div>
        <div id="footer-sec02-wrap">
          <div onClick={categoryToggle}>
            <div>Categories</div>
            <RiArrowDropDownLine id="footer-sec02-drop-icon" />
          </div>
          {category && (
            <div>
              <a href="/mattress">
                <div>Mattress</div>
              </a>
              <a href="pillow">
                <div>Pillows</div>
              </a>
              <a href="bestseller">
                <div>Best Sellers</div>
              </a>
            </div>
          )}
        </div>
        <div id="footer-sec03-wrap">
          <div>
            Show us some love <AiFillHeart id="footer-sec03-heart-icon" />
            on social media
          </div>

          <div id="footer-sec03-sc-media-icons-wrap">
            <div>
              <img src="./images/Footer-img/facebook.svg" alt="" />
            </div>
            <div>
              <img src="./images/Footer-img/Twitter.svg" alt="" />
            </div>
            <div>
              <img src="./images/Footer-img/Instagram.svg" alt="" />
            </div>
            <div>
              <img src="./images/Footer-img/Youtube.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    );
}
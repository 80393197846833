import "./Contact.css";

export const Contact=()=>{
    return (
      <div id="contact-us-page-main-wrapper">
        <div id="contact-us-page-banner-wrap">
          <div id="contact-us-page-banner-image-wrap">
            <img
              src="./images/Contact-img/contact.png"
              alt=""
              id="contact-us-page-banner-image"
            />
          </div>
          <div id="contact-us-page-banner-text-content">
            We craft mattress with best of raw materials to ensure the value for
            money is realized for every mattress that we sell.
          </div>
          <button id="contact-us-page-banner-button">REQUEST NOW</button>
        </div>

        <div id="contact-us-page-sec-02-wrap">
          <div id="contact-us-page-sec-02-title">Contact Us</div>
          <div id="contact-us-page-sec-02-sub-title">
            Share your specific needs, and our dedicated team will reach out to
            you promptly!
          </div>
          <div id="contact-us-page-sec-02-form-wrap">
            <form action="" id="contact-us-page-sec-02-form">
              <div>
                <input
                  type="text"
                  placeholder="Name"
                  id="contact-us-page-sec-02-form-name-input"
                />
              </div>
              <div>
                <input
                  type="email"
                  placeholder="Email Address"
                  id="contact-us-page-sec-02-form-email-input"
                />
              </div>
              <div>
                <input
                  type="text"
                  value="+91"
                  id="contact-us-page-sec-02-form-tel-code-input"
                />
                <input
                  type="tel"
                  minlength="10"
                  maxlength="10"
                  id="contact-us-page-sec-02-form-phone-input"
                  placeholder="Phone Number"
                />
              </div>
              <div>
                <textarea
                  name=""
                  cols="40"
                  rows="10"
                  placeholder="Share"
                  id="contact-us-page-sec-02-form-message-input"
                ></textarea>
              </div>
              <div id="contact-us-page-sec-02-form-text">
                Please include details of quantity, type of products, type of
                establishment, etc.
              </div>
              <div id="contact-us-page-sec-02-form-submit-button-wrap">
                <button id="contact-us-page-sec-02-form-submit-button">
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
}
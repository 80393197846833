import "./Pillow.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";

export const Pillow = () =>{

const navigate = useNavigate();

  const toCategory01=()=>{
navigate("/pillowcategory", {
  state: {
    id: 1,
    title: "Azalea Pillow",
    image: "./images/Pillow-img/pillow01.jpg",
    tagline:
      "The Azalea Pillow is a perfect blend of comfort and support for a restful night’s sleep.",
    description:
      "Crafted with attention to detail, it features a durable and hypoallergenic polyester in-fill, ensuring an allergen-free environment.The natural, breathable materials provide optimal airflow, keeping you cool throughout the night. With it’s re-fluffable and soft design, the pillow offers exceptional comfort and adjustable support.Made from 100% soft fabric, it feels luxurious against your skin. Suitable for back and side sleepers, it promotes proper alignment reducing discomfort.",
    feel: "Soft Mattress, Medium Soft",
    material: "Premium quality PU Foam",
  },
});
  }

    const toCategory02=()=>{
navigate("/pillowcategory", {
  state: {
    id: 2,
    title: "Camaellia Pillow ",
    image: "./images/Pillow-img/pillow02.webp",
    tagline:
      "The Camaellia Pillow is a perfect blend of comfort and support for a restful night’s sleep.",
    description:
      "The pillow is made of soft foam with a medium firm feel for sturdy support.The center of the pillow cushions the head while sleeping on your back. In turn, the sides are optimized for side sleeping, with breathable ear well to prevent pressure points from forming where the ears hit the pillow.",
    feel: "Medium Soft",
    material: "Soft Foam",
  },
});
  }

   const toCategory03 = () => {
     navigate("/pillowcategory", {
       state: {
         id: 3,
         title: "Memo Flex",
         image: "./images/Pillow-img/pillow03.webp",
         tagline:
           "The Memo Flex Pillow is a perfect blend of comfort and support for a restful night’s sleep.",
         description:
           "It keeps the hand shape for a bit, and so is said to have a memory of your hand’s impression. That is why this particular type of foam became known as memory foam.Memory foam pillows have emerged to be highly recommended pillows for neck pain relief and sound sleep. There are numerous reasons for memory foam pillow being the best choice for any type of sleepers.",
         feel: "Soft",
         material: "Memory Foam",
       },
     });
   };

      var pillow_banner_slider_settings = {
    className: "pillow-banner-slider-component",
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  var pillow_sec02_slider_settings = {
    className: "pillow-sec02-slider-component",
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

 return (
   <div id="pillow-page-main-wrapper">
     <div id="pillow-page-banner-slider-wrap">
       <Slider {...pillow_banner_slider_settings}>
         <div className="pillow-page-banner-slider01">
           <div className="pillow-page-banner-slider-title">Dreamwell</div>
           <div className="pillow-page-banner-slider-quote">
             "Tailored Comfort for Your Perfect Night's Rest: Your Dream Pillow,
             Customised Just for You!"
           </div>
           <a href="/contact">
             <button className="pillow-page-banner-slider-request-btn">
               Request now
             </button>
           </a>
         </div>

         <div className="pillow-page-banner-slider02">
           <div className="pillow-page-banner-slider-title">Dreamwell</div>
           <div className="pillow-page-banner-slider-quote">
             "Tailored Comfort for Your Perfect Night's Rest: Your Dream Pillow,
             Customised Just for You!"
           </div>
           <a href="/contact">
             <button className="pillow-page-banner-slider-request-btn">
               Request now
             </button>
           </a>
         </div>

         <div className="pillow-page-banner-slider03">
           <div className="pillow-page-banner-slider-title">Dreamwell</div>
           <div className="pillow-page-banner-slider-quote">
             "Tailored Comfort for Your Perfect Night's Rest: Your Dream Pillow,
             Customised Just for You!"
           </div>
           <a href="/contact">
             <button className="pillow-page-banner-slider-request-btn">
               Request now
             </button>
           </a>
         </div>
       </Slider>
     </div>

     <div id="pillow-page-sec02-wrap">
       <div id="pillow-page-sec02-headline">Pillows</div>
       <div id="pillow-page-sec02-slider-wrap">
         <Slider {...pillow_sec02_slider_settings}>
           <div className="pillow-page-sec02-card-wrap">
             <div className="pillow-page-sec02-card-image-wrap">
               <img
                 src="./images/Pillow-img/pillow01.jpg"
                 alt=""
                 className="pillow-page-sec02-card-image"
               />
             </div>
             <div className="pillow-page-sec02-card-title">Azalea</div>
             <div className="pillow-page-sec02-card-rating">
               <Stack spacing={1}>
                 <Rating name="read-only" value="5" readOnly size="small" />
               </Stack>
             </div>
             <div className="pillow-page-sec02-card-description">
               The azalea Pillow is a perfect blend of comfort and support for a
               restful night’s sleep,crafted with attention to detail.
             </div>
             <button
               className="pillow-page-sec02-card-button"
               onClick={() => {
                 toCategory01();
               }}
             >
               EXPLORE
             </button>
           </div>

           <div className="pillow-page-sec02-card-wrap">
             <div className="pillow-page-sec02-card-image-wrap">
               <img
                 src="./images/Pillow-img/pillow02.webp"
                 alt=""
                 className="pillow-page-sec02-card-image"
               />
             </div>
             <div className="pillow-page-sec02-card-title">Camaellia</div>
             <div className="pillow-page-sec02-card-rating">
               <Stack spacing={1}>
                 <Rating name="read-only" value="5" readOnly size="small" />
               </Stack>
             </div>
             <div className="pillow-page-sec02-card-description">
               The pillow is made of soft foam with a medium firm feel for
               sturdy support. The center of the pillow cushions the head.
             </div>
             <button
               className="pillow-page-sec02-card-button"
               onClick={() => {
                 toCategory02();
               }}
             >
               EXPLORE
             </button>
           </div>

           <div className="pillow-page-sec02-card-wrap">
             <div className="pillow-page-sec02-card-image-wrap">
               <img
                 src="./images/Pillow-img/pillow03.webp"
                 alt=""
                 className="pillow-page-sec02-card-image"
               />
             </div>
             <div className="pillow-page-sec02-card-title">Memo Flex</div>
             <div className="pillow-page-sec02-card-rating">
               <Stack spacing={1}>
                 <Rating name="read-only" value="5" readOnly size="small" />
               </Stack>
             </div>
             <div className="pillow-page-sec02-card-description">
               It keeps the hand shape for a bit, and so is said to have a
               memory of your hand’s impression.
             </div>
             <button
               className="pillow-page-sec02-card-button"
               onClick={() => {
                 toCategory03();
               }}
             >
               EXPLORE
             </button>
           </div>
         </Slider>
       </div>
     </div>
   </div>
 );
}
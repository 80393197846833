import "./Navbar.css";
import { RxHamburgerMenu } from "react-icons/rx";
import { CiSearch } from "react-icons/ci";
import { NavList } from "./NavList.jsx";
import { useState } from "react";

export const Navbar = () => {

   const [list, setList] = useState(false);

   const listToggle = () => {
     setList(true);
   };

   const listClose = () => {
     setList(false);
   };

  return (
    <>
      <div id="navbar-main-wrapper">
        <div id="navbar-header-div"></div>
        <div id="navbar-elements-container">
          <RxHamburgerMenu
            id="navbar-elements-hamburger-icon"
            onClick={listToggle}
          />
          <div id="navbar-elements-logo-wrap">
            <a href="/">
              <img src="./logo.png" alt="" id="navbar-elements-logo" />
            </a>
          </div>
          <CiSearch id="navbar-elements-search-icon" />
        </div>
        {list && <NavList listClose={listClose} />}
      </div>

      <div id="navbar-desktop-view-main-wrapper">
        <div id="navbar-desktop-view-elements-wrap">
          <div id="navbar-desktop-view-logo-wrap">
            <a href="/">
              <img src="./logo.png" alt="logo" id="navbar-desktop-view-logo" />
            </a>
          </div>
          <div id="navbar-desktop-view-search-wrap">
            <CiSearch id="navbar-desktop-view-search-icon" />
            <input type="search" placeholder="Search mattress" />
          </div>

          <div id="navbar-desktop-view-hyperlinks-wrap">
            <a href="/mattress">
              <div>Mattress</div>
            </a>

            <a href="/pillow">
              <div>Pillows</div>
            </a>

            <a href="/about">
              <div>About Us</div>
            </a>

            <a href="/customise">
              <div>Customise</div>
            </a>
          </div>
         
         <a href="/contact">
          <button id="navbar-desktop-view-contact-button">Contact Us</button>
         </a>
        </div>
      </div>
    </>
  );
};
